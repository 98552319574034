const app = angular.module('B360Website', ['ui.router', 'ngResource', 'angulartics', 'angulartics.google.analytics']);

app.config([
  '$stateProvider',
  '$urlRouterProvider',
  '$locationProvider',
  '$compileProvider',
  '$httpProvider',
  function($stateProvider, $urlRouterProvider, $locationProvider, $compileProvider, $httpProvider) {
    $httpProvider.useApplyAsync(true);
    /** Default Route */
    $urlRouterProvider.otherwise('/');
    /** Enables property URL's */
    $locationProvider.html5Mode(true);
    /** Remove debug statements in HTML */
    $compileProvider.debugInfoEnabled(false);

    /** Routes */
    $stateProvider
      .state('home-page', {
        url: '/',
        templateUrl: 'partials/home-page',
        controller: 'homePageController'
      })
      .state('case-studies', {
        url: '/case-studies',
        templateUrl: 'partials/case-studies',
        controller: 'caseStudiesController'
      });
  }
]);

/** ********************** */
/** ******** RUN ********* */
/** ********************** */
app.run([
  '$rootScope',
  '$transitions',
  function($rootScope, $transitions) {
    //* ******************************
    //* ********* FUNCTIONS **********
    //* ******************************
    /**
     * Updates the page title, page description and page og image on page
     * transition
     * @param {Object} toState - toState page transition object
     */
    function updatePageSEO() {
      // Default page titles, page descriptions and images
      const pageTitle = 'Building360 Energy Management Platform';
      const pageDescription =
        'Empowering Smarter Energy Decisions - Building360 is a leading data-driven platform that is trusted by energy professionals across the globe.';
      const pageImage = `${window.location.host}/images/og/measure-dashboard.png`;

      // FRONTEND: This is required if some/all pages will have their seperate SEO information.
      // Custom page tiles, descriptions and og images per page
      // switch (toState.name) {
      //   case 'home-page':
      //     pageTitle = '';
      //     pageDescription = '';
      //     break;
      //   case 'case-studies':
      //     pageTitle = '';
      //     pageDescription =
      //       '';
      //     break;
      // }

      // Update Title Tag
      document.getElementsByTagName('title')[0].text = pageTitle;

      // Update Meta Tags
      const metaList = document.getElementsByTagName('meta');

      // Iterating throught the meta list and update the values
      for (let i = 0; i < metaList.length; i++) {
        const metaTag = metaList[i];
        switch (metaTag.getAttribute('name')) {
          case 'og:title':
            metaTag.content = pageTitle;
            break;
          case 'description':
            metaTag.content = pageDescription;
            break;
          case 'og:image':
            metaTag.content = pageImage;
            break;
          default:
            break;
        }
      }
    }

    /** *************************** */
    /** ******** WATCHERS ********* */
    /** *************************** */

    $transitions.onBefore({}, (transition) => {
      $rootScope.homePage = transition.to().name === 'home-page';
      updatePageSEO(transition.to().name);
    });

    $transitions.onSuccess({}, () => {
      window.scrollTo(0, 0);
    });
  }
]);
