app.controller('caseStudiesController', [
  '$location',
  '$state',
  '$rootScope',
  '$q',
  function($location, $state, $rootScope, $q) {
    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
    /** Check to see if the url has a correct param. */
    function paramCheck() {
      if ($location.search().param !== undefined && window.caseStudyJson[$location.search().param] !== undefined) {
        $rootScope.caseStudy = window.caseStudyJson[$location.search().param];
        if ($rootScope.caseStudy.background_image !== null) {
          $rootScope.headerBg = `url('/images/${$rootScope.caseStudy.background_image}')`;
          $rootScope.headerHeight = 'calc(60vh - 76px)';
        }
      } else {
        $state.go('home-page');
      }
    }

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */
    if (window.caseStudyJson === undefined) {
      Promise.resolve().then(() =>
        $q((resolve) => {
          let json = null;
          $.ajax({
            async: false,
            global: false,
            url: '/copy-json/case-studies.json',
            dataType: 'json',
            success: (data) => {
              json = data;
            }
          });
          return resolve(json);
        }).then((caseStudy) => {
          window.caseStudyJson = caseStudy;
          paramCheck();
        })
      );
    } else {
      paramCheck();
    }
  }
]);
