app.controller('b360WebsiteController', [
  '$scope',
  '$rootScope',
  '$location',
  '$timeout',
  '$state',
  function($scope, $rootScope, $location, $timeout, $state) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */
    window.preventScroll = false;
    window.preventAllScroll = false;

    /** These are vanigation options. */
    $scope.navigationOptions = [
      { name: 'Action Based Philosophy' },
      { name: 'Markets' },
      { name: 'Packages' },
      { name: 'Testimonals' },
      { name: 'Sign In' }
    ];

    $timeout(() => {
      $rootScope.globalfadeIn = true;
    }, 500);

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */

    /**
     * This stores the section id. This is used for scrolling
     * to the desired section, once an option is clicked on
     */
    for (let i = 0; i < $scope.navigationOptions.length; i++) {
      const option = $scope.navigationOptions[i];
      option.id = option.name.replace(/\s+/g, '-').toLowerCase();
    }
    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */

    $rootScope.openModal = (type) => {
      $rootScope.modalType = type;
      $rootScope.showOverlay = true;
      $rootScope.showOverlayIndex = true;
      $timeout(() => {
        $rootScope.showModalIndex = true;
        $rootScope.showModal = true;
      }, 400);
    };

    /** This function exits the Burger Menu. */
    $scope.exitMenu = () => {
      $timeout(() => {
        $scope.openBurger = false;
      });
    };

    $rootScope.exitOverlay = function() {
      if ($rootScope.showModal === true) {
        $rootScope.showModal = false;
        $timeout(() => {
          $rootScope.showModalIndex = false;
          $rootScope.showOverlay = false;
          $rootScope.modalType = false;

          $timeout(() => {
            $rootScope.showOverlayIndex = false;
          }, 400);
        }, 400);
      }
    };

    $rootScope.goToUrl = (url) => {
      window.open(url, '_blank').focus();
    };

    /**
     * This function handles the scrolling to the correct section on the Home Page.
     *  @param {string} [id] - The id of the section
     *  @param {string} [direct] - (if true) -> This function was called by either a refresh or by hitting the browser back/forward button
     */
    $rootScope.goToSection = (id, direct) => {
      $scope.openBurger = false;
      if (id === 'sign-in') {
        $rootScope.openModal('signIn');
      } else {
        let headerOffset;
        const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (id !== 'testimonals') {
          headerOffset = 50;
        } else if (viewWidth < 899) {
          headerOffset = -150;
        } else {
          headerOffset = 0;
        }

        /**
         * (if !direct) -> add the focus param with the id as a value.
         * Then save that state to the browser's history.
         * This will allow the browsers back/forward to run those states
         */
        if (!direct) {
          const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?focus=${id}`;
          window.history.pushState({ path: newurl }, '', newurl);
        }

        setTimeout(() => {
          if (window.preventScroll) {
            return;
          }
          $('html, body').animate({ scrollTop: $(`#${id}`).offset().top - headerOffset }, '500');
        }, 50);
      }
    };

    /**
     * This function handles the scrolling to the correct "Designed For"
     * section on the Home Page. This can be found in mobile view.
     *  @param {string} [number] - The number represents the position of the item. This is required for the Carousel.
     */
    $rootScope.goToIndicator = (number) => {
      window.preventScroll = true;
      const headerOffset = 20;
      const id = `indicator-${number}`;
      setTimeout(() => {
        if (window.preventAllScroll) {
          return;
        }
        $('html, body').animate({ scrollTop: $(`#${id}`).offset().top - headerOffset }, '250');
      }, 100);

      setTimeout(() => {
        window.preventScroll = false;
        window.preventAllScroll = false;
      }, 150);
    };

    /**
     * Got to the Home Page and save that state to the browser's history.
     * This will allow the browsers back/forward to run those states
     */
    $rootScope.goToHomePage = () => {
      let newUrl = null;
      if (window.location.hostname === 'localhost') {
        const splitUrl = window.location.href.split('/');
        newUrl = `${splitUrl[0]}//${splitUrl[2]}`;
      } else {
        newUrl = `${window.location.protocol}//${window.location.hostname}`;
      }
      window.history.pushState({ path: newUrl }, '', newUrl);
      $state.go('home-page');
    };

    /** This redirects the user to the correct case study. */
    $rootScope.goToCaseStudy = () => {
      window.preventAllScroll = true;
      $location.url(`/case-studies?param=${$rootScope.currentCaseStudy}`);
    };

    /**
     * This function is used for the Desktop view of "Designed For".
     * This keeps track of the current carousel's item.
     */
    $rootScope.updateCurrentCase = (caseStudy) => {
      $timeout(() => {
        $rootScope.currentCaseStudy = caseStudy;
      });
    };
  }
]);
