app.controller('homePageController', [
  '$scope',
  '$rootScope',
  '$location',
  '$q',
  '$timeout',
  function($scope, $rootScope, $location, $q, $timeout) {
    /** **************************** */
    /** ******** VARIABLES ********* */
    /** **************************** */
    /** This sets the Home Page Header background image. */
    $rootScope.headerBg = 'url("/images/hero-bg.png")';
    $rootScope.currentCaseStudy = 'municipalities';
    $rootScope.headerHeight = null;

    /** **************************** */
    /** ******** FUNCTIONS ********* */
    /** **************************** */
    function checkParam() {
      if ($location.search().focus !== undefined) {
        $rootScope.goToSection($location.search().focus, true);
      }
    }

    /** Gets the Designed For Copy. */
    function getDesignFor() {
      return $q((resolve) => {
        let json = null;
        $.ajax({
          async: false,
          global: false,
          url: '/copy-json/designed-for.json',
          dataType: 'json',
          success: (data) => {
            json = data;
          }
        });
        return resolve(json);
      });
    }

    /** Gets the Quotes Copy. */
    function getQuotes() {
      return $q((resolve) => {
        let json = null;
        $.ajax({
          async: false,
          global: false,
          url: '/copy-json/case-studies.json',
          dataType: 'json',
          success: (data) => {
            json = data;
          }
        });
        return resolve(json);
      });
    }

    /** ********************************* */
    /** ******** INIT FUNCTIONS ********* */
    /** ********************************* */
    checkParam();

    /**
     * If the Quotes or the Designed For copy is missing,
     * get the copy from the appropriate json and store it.
     */
    if ($rootScope.quotes === undefined || $rootScope.designedFor === undefined) {
      Promise.resolve()
        .then(() => $q.all([getDesignFor(), getQuotes()]))
        .then((json) => {
          const caseStudies = json[1];
          const quotes = [];
          for (const key in caseStudies) {
            const caseStudy = caseStudies[key];
            if (caseStudy.quote !== undefined) {
              const quote = caseStudy.quote;
              quote.logo = `url('/images/${caseStudy.logo}')`;
              quote.description = [ quote.description[Math.floor(quote.description.length * Math.random())] ];
              quotes.push(quote);
            }
          }
          for (let k=0; k<quotes.length; k++) {
            const a = Math.floor(Math.random() * quotes.length);
            const b = Math.floor(Math.random() * quotes.length);
            const t = quotes[a];
            quotes[a] = quotes[b];
            quotes[b] = t;
          }
          $timeout(() => {
            $rootScope.designedFor = json[0];
            $rootScope.quotes = quotes;
            setTimeout(() => {
              $('.carousel').carousel({ interval: false });
            }, 50);
          });
        });
    }

    /**
     * This function creates and sets the accordian for the Mobile View for
     * "Designed For"'s options.
     */
    const carouselIndicators = document.getElementsByClassName('carousel-indicators')[0];
    for (let i = 0; i < carouselIndicators.children.length; i++) {
      const li = carouselIndicators.children[i];
      const number = li.getAttribute('data-slide-to');
      const id = `indicator-${number}`;
      li.setAttribute('id', id);
    }

    /** This triggers when the browser back/forward button is triggered. */
    (function() {
      if (window.history && window.history.pushState) {
        $(window).on('popstate', () => {
          checkParam();
        });
      }
    })();

    /** ********************************** */
    /** ******** SCOPE FUNCTIONS ********* */
    /** ********************************** */
    // FRONTEND: This isn't needed, unless if the header is sticky.
    // $scope.selectNav = (option) => {
    //   $timeout(() => {
    //     $scope.selectOption = option;
    //   })
    // }

    $scope.requeestQuote = () => {
      window.open('https://simptekinc.com/contact-us?param="real-time"', '_blank').focus();
    };
  }
]);
